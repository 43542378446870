<template>
  <AppCard>
    <template #header>
      <XeStepperCardHeader
        :title="$t('ComponentBankDeposit.Header').value"
        analytics-name="xe-send-money-delivery-bank-account"
      />
    </template>
    <AppInputSearch
      ref="userInputRef"
      v-model="searchInput"
      class="bank-input-search"
      analytics-name="bank-deposit-modal-search"
      :label="$t('ComponentBankDeposit.LabelSearch').value"
      :dismissed="!showBankSelection"
      :items="searchedBanks"
      :loading="loadingBanks"
      :min-search-length="3"
      @focus="focusBankDeposit"
      @search="searchBankDeposit"
      @select="selectBank"
      @clear="clearBankDepositSearch"
    >
      <template #empty>
        <span />
      </template>
      <template #noMatches>
        <div class="search-results-empty search-results-empty-xs">
          <h3 class="type-h3">
            {{ $t('ComponentBankDeposit.NoBanksTitle').value }}
          </h3>
          <h6>{{ $t('ComponentBankDeposit.NoBanksDescription').value }}</h6>
        </div>
      </template>
    </AppInputSearch>

    <div v-if="noBanks" class="search-results-empty">
      <h3 class="type-h3">
        {{ $t('ComponentBankDeposit.NoBanksTitle').value }}
      </h3>
      <h6>{{ $t('ComponentBankDeposit.NoBanksDescription').value }}</h6>
    </div>

    <div v-if="invalidBank" class="search-results-empty">
      <h3 class="type-h3">
        {{ $t('ComponentBankDeposit.InvalidBankTitle').value }}
      </h3>
      <h6>
        {{ $t('ComponentBankDeposit.InvalidBankDescription').value }}
      </h6>
    </div>

    <Transition name="fade">
      <AppDynamicForm v-if="selectedBankId" :fields="formFields"></AppDynamicForm>
    </Transition>

    <template #footer>
      <AppCardFooter v-if="hasFormFields">
        <AppButton
          analytics-name="bank-deposit-modal-continue"
          :disabled="isFormInvalid || !selectedBankId"
          :loading="loadingSubmitDeposit"
          @click="submitBankDeposit()"
          >{{ $t('ComponentBankDeposit.ButtonContinue').value }}</AppButton
        >
      </AppCardFooter>
    </template>
  </AppCard>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'

import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import { DeliveryMethod } from '@galileo/models/Transaction/app'
import { SEGMENT_EVENTS, SEGMENT_LOCATIONS } from '@galileo/constants/segmentAnalytics'
import { useBankDepositForm } from '@galileo/forms/BankDepositForm'


import { AppDynamicForm, AppInputSearch, AppButton, AppCard, AppCardFooter } from '@oen.web.vue2/ui'



import { useAnalyticsStore, useAppStore, useRecipientsStore, useSendMoneyStore, useI18nStore  } from '@galileo/stores'

export default {
  name: 'BankDeposit',
  emits: ['input', 'hidden', 'submit'],
  components: {
    AppCard,
    AppDynamicForm,
    AppInputSearch,
    AppButton,
    XeStepperCardHeader,
    AppCardFooter,
  },
  setup() {
    const showBankSelection = ref(true)

    const { $t } = useI18nStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()

    const country = sendMoneyStore.getCountryTo
    const currency = sendMoneyStore.form.currencyTo

    const { setup, formFields, hasFormFields, isFormInvalid } = useBankDepositForm(null)

    const userInputRef = ref(null)

    const selectedRecipient = computed(() => sendMoneyStore.getRecipient)

    onMounted(async () => {
      let bankName = selectedRecipient?.value.bankName
      let bankId = selectedRecipient?.value.bankId
      setup([])
      clearBankDepositSearch()

      if (bankName && bankId >= 0) {
        // TODO: Search result string may be different from bankLocation name slightly, but has the same id
        await selectBank({ name: bankName.toLowerCase(), value: bankId })
        if (!invalidBank.value) {
          for (let field of formFields.value) {
            field.value = selectedRecipient.value[field.id]
          }
        }
      } else {
        userInputRef.value.focus()
      }
    })

    const searchInput = ref('')

    const clearBankDepositSearch = () => {
      searchInput.value = ''
      searchedBanks.value = []
      noBanks.value = false
      invalidBank.value = false
      showBankSelection.value = true
    }

    const noBanks = ref(false)
    const invalidBank = ref(false)
    const lastBankSearch = ref('')
    const selectedBankId = ref('')
    const selectedBankName = ref('')
    const loadingBanks = ref(false)
    const loadingSubmitDeposit = ref(false)
    const searchedBanks = ref([])
    let bankDepositFields = []

    const focusBankDeposit = () => {
      showBankSelection.value = true
    }
    const searchBankDeposit = async (searchValue) => {
      searchedBanks.value = []
      if (lastBankSearch.value === searchValue) {
        return
      }

      selectedBankId.value = ''
      selectedBankName.value = ''
      if (searchValue && searchValue.length >= 3) {
        try {
          loadingBanks.value = true
          invalidBank.value = false
          const bankResult = await recipientsStore.searchBank({
            nameOrCode: searchValue,
            country: country,
          })
          if (!bankResult)
            // if result was discarded due to multiple requests leave here
            return
          searchedBanks.value = bankResult

          noBanks.value = searchedBanks.value.length === 0

          loadingBanks.value = false

          if (
            searchedBanks.value.length === 1 &&
            searchValue === searchedBanks.value[0].name.trim()
          ) {
            const bank = searchedBanks.value[0]
            searchedBanks.value = []
            await selectBank(bank)
          }
        } catch (ex) {
          appStore.logException('Exception during searchBankDeposit', ex)
        }
      }
      loadingBanks.value = false
    }

    const selectBank = async (item) => {
      loadingBanks.value = true
      noBanks.value = false
      lastBankSearch.value = item.name
      searchInput.value = item.name
      try {
        bankDepositFields = await recipientsStore.getBankFields({
          bankId: item.value,
          country: country,
          currency: currency,
        })
      } catch (ex) {
        appStore.logException('Exception during bank selection', ex)
        bankDepositFields = []
      }

      if (!bankDepositFields || bankDepositFields.length === 0) {
        invalidBank.value = true
      } else {
        setup(bankDepositFields)
        selectedBankId.value = item.value
        selectedBankName.value = item.name
        showBankSelection.value = false
        searchedBanks.value = [item]
      }
      loadingBanks.value = false
    }

    const isBankDataChanged = (recipient) => {
      for (let field of formFields.value) {
        if (recipient[field.id] !== selectedRecipient.value[field.id]) {
          return true
        }
      }
      return recipient.bankId !== selectedRecipient.value.bankId
    }

    const submitBankDeposit = async () => {
      if (isFormInvalid.value) {
        return
      }
      loadingSubmitDeposit.value = true
      const recipient = { ...selectedRecipient.value }
      for (let field of formFields.value) {
        recipient[field.id] = field.value
      }
      recipient.bankId = selectedBankId.value
      recipient.bankName = selectedBankName.value

      try {
        // TODO: Ensure currency sent to saveBankDeposit is irrelevant to isBankDataChanged (so it's not changed on this route)
        if (isBankDataChanged(recipient)) {
          // TODO: Update selected recipient and recipients list to have Recipient step and Bank Account step updated
          await recipientsStore.saveBankDeposit({ recipient, currency })
          // updated store.recipient with new bank details
          sendMoneyStore.form.recipient = recipient
        }
        // For new bank account details we have to mask those

        sendMoneyStore.updateDeliveryMethodText()
        analyticsStore.track({
          event: SEGMENT_EVENTS.RECIPIENT_BANK_DETAILS_ADDED,
          traits: {
            location: SEGMENT_LOCATIONS.SEND_MONEY,
            recipientBankId: recipient.bankId,
            recipientBankName: recipient.bankName,
          },
        })

        sendMoneyStore.goToNextStep()
        loadingSubmitDeposit.value = false
      } catch (ex) {
        appStore.logException('Exception during saving bank deposit', ex)

        if (ex.errorCode === 'InvalidUnitaryAccountNumber') {
          appStore.messageBoxGenericOk(ex.friendlyMessage)
        } else {
          appStore.messageBoxGenericError()
        }
      } finally {
        loadingSubmitDeposit.value = false
      }
    }

    return {
      showBankSelection,
      hasFormFields,
      formFields,
      isFormInvalid,
      noBanks,
      invalidBank,
      loadingBanks,
      searchInput,
      searchedBanks,
      searchBankDeposit,
      focusBankDeposit,
      selectBank,
      submitBankDeposit,
      selectedBankId,
      clearBankDepositSearch,
      loadingSubmitDeposit,
      $t,
      userInputRef,
    }
  },
}
</script>

<style scoped>
.search-results-empty {
  @apply flex flex-col items-center text-center;

  &.search-results-empty-xs {
    @apply h-full justify-center;
  }
}

.bank-input-search {
  ::v-deep .item-text {
    @apply capitalize;
  }
  ::v-deep .input-input {
    @apply capitalize;
  }
}
</style>
