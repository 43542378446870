<template>
  <div class="pickup-map">
    <AppCard>
      <template #header>
        <XeStepperCardHeader
          :title="selectedAgent.name + ' ' + $t('ComponentPickupMap.Header').value"
          analytics-name="xe-send-money-delivery-pickup-locations"
        />
      </template>
      <div class="map-layout">
        <div
          class="map-list"
          :class="{
            'map-list-loading': selectedAgentLocations.length === 0,
          }"
        >
          <AppSpinnerBig :loading="selectedAgentLocations.length === 0" />

          <AppList>
            <RiaListItem
              v-for="location in selectedAgentLocations"
              :key="location.id"
              :analytics-name="'pickup-map-item-' + location.id"
              :title="location.name"
              :sub-title="`${location.street}, ${location.city}, ${location.state}`"
              :hide-figure="true"
              @itemClick="selectAgentLocation(location)"
            >
              <template #action>
                <AppInputRadioButtons
                  :value="
                    selectedAgentLocationId
                      ? location.id === selectedAgentLocationId
                        ? '1'
                        : '0'
                      : '0'
                  "
                  :analytics-name="'pickup-map-modal-item-' + location.id + '-radio'"
                  :options="[
                    {
                      value: '1',
                      disabled: selectedAgentLocationId && location.id !== selectedAgentLocationId,
                    },
                  ]"
                >
                </AppInputRadioButtons>
              </template>
            </RiaListItem>
          </AppList>
        </div>
        <div class="map-area">
          <XeMap
            :center-lat="centeredCoords.lat"
            :center-lng="centeredCoords.lng"
            :bounding-box="locationMarkersBoundingBox"
          >
            <template #markers>
              <MglMarker
                v-for="location in selectedAgentLocations"
                :key="location.id"
                :coordinates="[location.longitude, location.latitude]"
                color="orange"
                @click="selectAgentLocation(location)"
              >
                <div slot="marker">
                  <img
                    v-if="selectedAgentLocationId && location.id === selectedAgentLocationId"
                    alt="Pin Selected"
                    :src="require('@galileo/assets/images/misc/map-pin-selected.svg')"
                  />
                  <img v-else alt="Pin" :src="require('@galileo/assets/images/misc/map-pin.svg')" />
                </div>
              </MglMarker>
            </template>
          </XeMap>
          <AppButton
            class="continue-button hidden absolute bottom-6 right-6 sm:block"
            :disabled="!selectedAgentLocation"
            analytics-name="pickup-map-modal-continue"
            @click="submitAgentLocation"
          >
            {{ $t('ComponentPickupMapModal.Buttons.Continue').value }}
          </AppButton>
        </div>
      </div>

      <template #footer>
        <AppCardFooter>
          <AppButton
            class="continue-button sm:hidden"
            :disabled="!selectedAgentLocation"
            analytics-name="pickup-map-modal-continue"
            @click="submitAgentLocation"
          >
            {{ $t('ComponentPickupMapModal.Buttons.Continue').value }}
          </AppButton>
        </AppCardFooter>
      </template>
    </AppCard>
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import XeMap from '@galileo/components/XeMap/XeMap'
import { MglMarker } from 'vue-mapbox'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import { SEGMENT_EVENTS, SEGMENT_PAYOUT_AGENT_TYPE } from '@galileo/constants/segmentAnalytics'
import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'
import Mapbox from 'mapbox-gl'


import {
  AppButton,
  AppSpinnerBig,
  AppList,
  AppInputRadioButtons,
  AppCard,
  AppCardFooter,
} from '@oen.web.vue2/ui'


import { useAnalyticsStore, useRecipientsStore, useSendMoneyStore, useI18nStore } from '@galileo/stores'

export default {
  name: 'PickupMap',
  emits: [],
  components: {
    RiaListItem,
    AppInputRadioButtons,
    AppButton,
    AppSpinnerBig,
    AppList,
    XeMap,
    MglMarker,
    AppCard,
    XeStepperCardHeader,
    AppCardFooter,
  },
  setup() {
    const { $t } = useI18nStore()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()

    const selectedAgent = computed(() => recipientsStore.selectedAgent)
    const selectedAgentLocations = computed(() => recipientsStore.selectedAgentLocations)

    const userSelection = ref(null)
    const selectedAgentLocation = computed(() => {
      return userSelection.value || null
    })

    const selectedAgentLocationId = computed(() => {
      if (selectedAgentLocation.value) {
        return selectedAgentLocation.value.id
      } else {
        return null
      }
    })

    const locationMarkersBoundingBox = computed(() => {
      if (selectedAgentLocations.value) {
        const boundingBox = new Mapbox.LngLatBounds()
        selectedAgentLocations.value.forEach((location) => {
          boundingBox.extend(new Mapbox.LngLat(location.longitude, location.latitude))
        })
        return boundingBox
      }
    })

    const centeredCoords = computed(() => {
      if (selectedAgentLocation.value) {
        return {
          lat: selectedAgentLocation.value.latitude,
          lng: selectedAgentLocation.value.longitude,
        }
      } else {
        return {
          lat: null,
          lng: null,
        }
      }
    })

    const selectAgentLocation = (agentLocation) => {
      userSelection.value = agentLocation
    }

    const submitAgentLocation = () => {
      sendMoneyStore.form.deliveryMethodLocation = selectedAgentLocation.value
      sendMoneyStore.form.deliveryMethodAgent = selectedAgent.value
      sendMoneyStore.updateDeliveryMethodText()

      analyticsStore.track({
        event: SEGMENT_EVENTS.PAYOUT_LOCATION_SELECTED,
        traits: {
          payoutCountryType: SEGMENT_PAYOUT_AGENT_TYPE.POINT_TO_POINT,
          payoutAgentName: selectedAgent.value.name,
          payoutLocationId: selectedAgentLocation.value.id,
          payoutLocationName: selectedAgentLocation.value.name,
          payoutLocationAddress:
            selectedAgentLocation.value.street +
            ', ' +
            selectedAgentLocation.value.street2 +
            ', ' +
            selectedAgentLocation.value.state,
        },
      })

      sendMoneyStore.goToNextStep()
    }

    return {
      selectAgentLocation,
      centeredCoords,
      selectedAgent,
      selectedAgentLocation,
      selectedAgentLocations,
      selectedAgentLocationId,
      submitAgentLocation,
      locationMarkersBoundingBox,
      $t,
    }
  },
}
</script>

<style scoped>
.pickup-map {
  @apply flex justify-center;

  ::v-deep .card {
    @apply overflow-hidden;
    max-width: 1088px; /* as per Figma */

    .card-header {
      @screen sm {
        @apply py-10;
        @apply border-b border-gray-lighter;
      }

      .card-header-title {
        @apply text-lg;
      }
    }
    .card-content {
      @apply grid;
      @apply p-0;
      @apply overflow-hidden;
      @screen sm {
        @apply border-t border-gray-lighter;
        @apply pt-0 pr-0 pb-0;
        /* No Tailwind equivalents */
        min-height: max(400px, calc(100vh - 96px - 32px - 1px - 64px - 1px - 128px - 1px));
        /*screen - nav - border - margin - footer - border - header - border*/
        height: calc(100vh - 96px - 32px - 1px - 64px - 1px - 128px - 1px);
      }
    }

    .card-content-block {
      @apply h-full;
      @apply mb-1.5;
    }

    @screen sm {
      @apply mx-6;

      &.card--has-footer {
        @apply pb-0;
      }

      .card-footer {
        @apply p-0;

        .button {
          @apply absolute -mt-18 mr-6 z-100;
        }
      }
    }
  }

  .map-layout {
    @apply h-full;
    @apply grid grid-rows-5;

    /* @screen sm {
      @apply grid-rows-4;
    } */

    @screen smL {
      grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
    }

    @screen md {
      grid-template-columns: minmax(0, 4fr) minmax(0, 3fr) minmax(0, 3fr);
    }
  }

  .map-list {
    @apply h-full;
    @apply row-span-3;
    @apply order-2;

    @screen sm {
      @apply pb-12;
    }

    @screen smL {
      @apply row-span-5 col-span-1;
      @apply order-1;
    }

    ::v-deep {
      .list {
        @apply mt-0 overflow-auto border-b border-gray-lighter h-full;
      }
      .list-item-button {
        @apply pl-12;
        @apply rounded-none;
        @apply shadow-none;
      }
      .list-item-content {
        @apply py-10;
      }
      .list-item-title {
        @apply text-base font-medium leading-5;
      }
      .list-item-caption {
        @apply text-xs font-normal leading-4;
        padding-top: 6px;
      }
      .list-item-action {
        @apply mr-6;
      }
      .list-item-action .input-radio-buttons label {
        @apply m-0;
      }
    }
  }

  .map-list-loading {
    @apply flex flex-col justify-center;
    ::v-deep .loading-spinner-container {
      @apply mt-0;
    }
  }

  .map-area {
    @apply row-span-2;
    @apply order-1;
    @apply relative;

    @screen smL {
      @apply row-span-5 col-span-1;
      @apply order-2;
    }

    @screen md {
      @apply col-span-2;
    }
  }

  .continue-button {
    @screen sm {
      width: calc(100% - 3rem);
    }

    &:disabled {
      &:not(.button--loading) {
        @apply text-white;
      }
      /*Fixes the button being transparent by design*/
      background: linear-gradient(
          to right,
          theme('colors.blue.button-disabled'),
          theme('colors.blue.button-disabled')
        ),
        linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
    }
  }
}
</style>
