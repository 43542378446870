var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pickup-map"},[_c('AppCard',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('XeStepperCardHeader',{attrs:{"title":_vm.selectedAgent.name + ' ' + _vm.$t('ComponentPickupMap.Header').value,"analytics-name":"xe-send-money-delivery-pickup-locations"}})]},proxy:true},{key:"footer",fn:function(){return [_c('AppCardFooter',[_c('AppButton',{staticClass:"continue-button sm:hidden",attrs:{"disabled":!_vm.selectedAgentLocation,"analytics-name":"pickup-map-modal-continue"},on:{"click":_vm.submitAgentLocation}},[_vm._v(" "+_vm._s(_vm.$t('ComponentPickupMapModal.Buttons.Continue').value)+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"map-layout"},[_c('div',{staticClass:"map-list",class:{
          'map-list-loading': _vm.selectedAgentLocations.length === 0,
        }},[_c('AppSpinnerBig',{attrs:{"loading":_vm.selectedAgentLocations.length === 0}}),_c('AppList',_vm._l((_vm.selectedAgentLocations),function(location){return _c('RiaListItem',{key:location.id,attrs:{"analytics-name":'pickup-map-item-' + location.id,"title":location.name,"sub-title":((location.street) + ", " + (location.city) + ", " + (location.state)),"hide-figure":true},on:{"itemClick":function($event){return _vm.selectAgentLocation(location)}},scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('AppInputRadioButtons',{attrs:{"value":_vm.selectedAgentLocationId
                    ? location.id === _vm.selectedAgentLocationId
                      ? '1'
                      : '0'
                    : '0',"analytics-name":'pickup-map-modal-item-' + location.id + '-radio',"options":[
                  {
                    value: '1',
                    disabled: _vm.selectedAgentLocationId && location.id !== _vm.selectedAgentLocationId,
                  } ]}})]},proxy:true}],null,true)})}),1)],1),_c('div',{staticClass:"map-area"},[_c('XeMap',{attrs:{"center-lat":_vm.centeredCoords.lat,"center-lng":_vm.centeredCoords.lng,"bounding-box":_vm.locationMarkersBoundingBox},scopedSlots:_vm._u([{key:"markers",fn:function(){return _vm._l((_vm.selectedAgentLocations),function(location){return _c('MglMarker',{key:location.id,attrs:{"coordinates":[location.longitude, location.latitude],"color":"orange"},on:{"click":function($event){return _vm.selectAgentLocation(location)}}},[_c('div',{attrs:{"slot":"marker"},slot:"marker"},[(_vm.selectedAgentLocationId && location.id === _vm.selectedAgentLocationId)?_c('img',{attrs:{"alt":"Pin Selected","src":require('@galileo/assets/images/misc/map-pin-selected.svg')}}):_c('img',{attrs:{"alt":"Pin","src":require('@galileo/assets/images/misc/map-pin.svg')}})])])})},proxy:true}])}),_c('AppButton',{staticClass:"continue-button hidden absolute bottom-6 right-6 sm:block",attrs:{"disabled":!_vm.selectedAgentLocation,"analytics-name":"pickup-map-modal-continue"},on:{"click":_vm.submitAgentLocation}},[_vm._v(" "+_vm._s(_vm.$t('ComponentPickupMapModal.Buttons.Continue').value)+" ")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }