<template>
  <RouterView />
</template>

<script>
import { computed } from '@vue/composition-api'

import { useRouter } from '@galileo/composables/useRouter'

import { useMediaQuery } from '@oen.web.vue2/ui'

import { useI18nStore, useSendMoneyStore } from '@galileo/stores'

export default {
  name: 'SendMoneyDelivery',
  setup(props, { emit }) {
    const router = useRouter()
    const { $t } = useI18nStore()
    const mq = useMediaQuery()
    const sendMoneyStore = useSendMoneyStore()

    // Tell the store which step we are on
    sendMoneyStore.setActiveStep(router.currentRoute.path)

    const steps = sendMoneyStore.steps
    steps.forEach((step) => {
      step.title = $t(step.titleKey).value
      step.pageTitle = $t(step.pageTitleKey).value
    })

    
    const activeStepIdx = computed(() => {
      return sendMoneyStore.activeStepIdx
    })

    const activeStep = computed(() => {
      return sendMoneyStore.activeStep
    })

    const close = async () => {
      sendMoneyStore.clearCardData() // to be on the safe side try to get rid of it
      await router.goBackTo(
        {
          name: 'Activity',
        },
        true
      )
    }

    return {
      mq,
      steps,
      close,
      activeStepIdx,
      activeStep,
      $t,
    }
  },
}
</script>

<style scoped></style>
