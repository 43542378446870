<template>
  <div>
    <AppSpinnerBig :loading="loading" />
    <AppCard :class="blur">
      <template #header>
        <XeStepperCardHeader
          :title="$t('ComponentPickup.Header').value"
          analytics-name="xe-send-money-delivery-pickup-agents"
        />
      </template>

      <AppInputSearch
        ref="searchInputRef"
        v-model="searchInput"
        analytics-name="pickup-city"
        :label="$t('ComponentPickup.LabelSearch').value"
        :items="searchedCities"
        :loading="loadingCities"
        :dismissed="hideCities"
        title-field="cityName"
        subtitle-field="stateName"
        @select="selectCity"
        @search="searchCity"
        @clear="searchInput = ''"
        @dismiss="hideCities = false"
      ></AppInputSearch>

      <div v-if="!loadingCities && !searchedAgents.length" class="results-empty">
        <h3 class="type-h3">
          {{ $t('ComponentPickup.NoAgentsTitle').value }}
        </h3>
        <h6>{{ $t('ComponentPickup.NoAgentsDescription').value }}</h6>
      </div>

      <AppSkeleton
        class="cities-skeleton"
        height="64px"
        :count="1"
        :loading="loadingCities"
      ></AppSkeleton>

      <AppList v-show="!loadingCities" id="agent-list">
        <RiaListItem
          v-for="agent in searchedAgents"
          :key="agent.id"
          :analytics-name="'pickup-agent' + agent.id"
          @itemClick="selectAgent(agent)"
        >
          <template #figure>
            <AppFigure theme="gray" class="agent-figure">
              <img class="agent-logo" :src="logoBaseUrl + agent.id" alt="" />
              <AppIcon name=" ">
                <IconStore />
              </AppIcon>
            </AppFigure>
          </template>
          <template #left>
            <AppListItemTitle>{{ agent.name }}</AppListItemTitle>

            <AppListItemCaption v-if="agent.locationCount > 1 && agent.openPayment">
              {{
                $t('ComponentPickup.AnyLocationPreText').value +
                ' ' +
                agent.locationCount +
                ' ' +
                $t('ComponentPickup.AnyLocationPostText').value
              }}
            </AppListItemCaption>
            <AppListItemCaption v-if="agent.locationCount > 1 && !agent.openPayment">
              {{
                $t('ComponentPickup.PickOnePreText').value +
                ' ' +
                agent.locationCount +
                ' ' +
                $t('ComponentPickup.PickOnePostText').value
              }}
            </AppListItemCaption>
            <AppListItemCaption v-if="agent.locationCount <= 1">
              <AppLink analytics-name="pickup-details" @click.stop="showAgentDetails(agent)">
                {{ $t('ComponentPickup.ButtonDetails').value }}

                <template #rightIcon>
                  <AppIcon name=" " size="16">
                    <IconLaunch></IconLaunch>
                  </AppIcon>
                </template>
              </AppLink>
            </AppListItemCaption>
            <AppListItemCaption v-if="agent.customerRate">
              {{ `${formatCurrency(currencyFrom, 1)} ${currencyFrom} =` }}
              <span class="text-blue-text">{{
                `${formatExchangeRate(agent.customerRate)} ${agent.currency}`
              }}</span>
            </AppListItemCaption>
          </template>
        </RiaListItem>
      </AppList>
    </AppCard>
    <!-- Pickup Details Dialog -->
    <AgentDetailsDialog
      v-if="agentDetails"
      v-model="showAgentDetailsModal"
      :agent-details="agentDetails"
      :loading="agentDetailsLoading"
    />
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'

import AgentDetailsDialog from '@galileo/components/Views/Delivery/AgentDetailsDialog'
import RiaListItem from '@galileo/components/RiaListItem/RiaListItem'

import { SEGMENT_EVENTS, SEGMENT_PAYOUT_AGENT_TYPE } from '@galileo/constants/segmentAnalytics'
import XeStepperCardHeader from '@galileo/components/XeStepperCardHeader/XeStepperCardHeader'
import { useRouter } from '@galileo/composables/useRouter'

import { IconLaunch, IconStore } from '@oen.web.vue2/icons'

import { formatCurrency, formatExchangeRate } from '@galileo/composables/useCurrency'

import {
  useTransition,
  AppList,
  AppIcon,
  AppSkeleton,
  AppLink,
  AppFigure,
  AppInputSearch,
  AppListItemTitle,
  AppListItemCaption,
  AppCard,
  AppSpinnerBig,
} from '@oen.web.vue2/ui'

import { STEPS } from '@galileo/constants/sendMoneyFlow.const'

import {
  useAnalyticsStore,
  useAppStore,
  useRecipientsStore,
  useSendMoneyStore,
  useI18nStore,
  useEnvStore,
} from '@galileo/stores'

export default {
  name: 'Pickup',
  emits: ['input', 'showAgentDetails'],
  components: {
    RiaListItem,
    AppListItemCaption,
    AppListItemTitle,
    AppList,
    AppIcon,
    AppSkeleton,
    IconLaunch,
    IconStore,
    AppLink,
    AppFigure,
    AppInputSearch,
    XeStepperCardHeader,
    AppCard,
    AppSpinnerBig,
    AgentDetailsDialog,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()

    const loading = ref(false)
    const blur = useTransition('blur', loading)

    const envStore = useEnvStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()
    const recipientsStore = useRecipientsStore()
    const sendMoneyStore = useSendMoneyStore()

    const logoBaseUrl = envStore.env.VUE_APP_ENDPOINTS_RMTAPI + 'agent/logo/'
    const country = sendMoneyStore.getCountryTo
    const currency = sendMoneyStore.form.currencyTo
    const currencyFrom = sendMoneyStore.getCurrencyFrom

    const selectedRecipient = computed(() => sendMoneyStore.getRecipient)

    const searchInput = ref('')
    const selectedCity = ref(null)

    const showAgentDetailsModal = ref(false)
    const agentDetails = ref(null)
    const agentDetailsLoading = ref(false)
    const searchedAgents = ref([])

    const loadingCities = ref(true)
    const searchedCities = ref(null)
    const hideCities = ref(false)
    const searchCity = async (searchValue) => {
      searchedAgents.value = []
      if (!searchValue) {
        return {}
      }

      searchValue = searchValue.split(',')
      loadingCities.value = true
      let cities = await recipientsStore.searchCity({
        city: searchValue[0],
        country: country,
        deliveryMethod: sendMoneyStore.form.deliveryMethod,
      })

      loadingCities.value = false
      searchedCities.value = cities

      // Check search for exact match on the results
      if (searchValue.length > 1) {
        for (let i = 0; i < cities.length; i++) {
          const city = cities[i]
          if (
            city.cityName.toLowerCase() === searchValue[0].trim().toLowerCase() &&
            city.stateName.toLowerCase() === searchValue[1].trim().toLowerCase()
          ) {
            try {
              hideCities.value = true
              await selectCity(city)
            } catch (ex) {
              await appStore.logException('Exception during selectCity', ex)
            }
            break
          }
        }
      }
    }

    const selectCity = async (city) => {
      loadingCities.value = true
      searchInput.value = city.name
      searchedAgents.value = await recipientsStore.getAgentsByCity({
        city: city,
        country: country,
        deliveryMethod: sendMoneyStore.form.deliveryMethod,
        currency: currency,
        amount: sendMoneyStore.getAmountTo(false),
      })
      loadingCities.value = false
      selectedCity.value = city

      window.requestAnimationFrame(() => {
        const imgs = document.getElementById('agent-list').getElementsByClassName('agent-logo')
        for (let i = 0; i < imgs.length; i++) {
          imgs[i].onload = () => {
            imgs[i].nextSibling.style.display = 'none'
          }
        }
      })
    }

    const selectAgent = async (agent) => {
      loading.value = true
      try {
        await recipientsStore.setSelectedAgentLocations([])

        await recipientsStore.setSelectedAgent(agent)

        let deliveryMethod = sendMoneyStore.form.deliveryMethod

        const locations = await recipientsStore.getAgentLocations({
          agent: agent,
          country: country,
          city: selectedCity.value,
          amount: sendMoneyStore.getAmountTo(false),
          deliveryMethod,
          currency: currency,
        })
        if (locations) {
          analyticsStore.track({
            event: SEGMENT_EVENTS.PICKUP_AGENT_SELECTED,
            traits: {
              payoutAgentId: agent.id,
              payoutAgentName: agent.name,
              payoutAgentType: agent.openPayment ? ' Open Network' : 'Point-to-point',
            },
          })
          if (agent.locationCount > 1 && !agent.openPayment) {
            recipientsStore.setSelectedAgentLocations(locations)
            router.push({ name: STEPS.SEND_MONEY_CHOOSE_LOCATION })
          } else {
            try {
              if (locations) {
                // TODO: error handling
                if (!agent.openPayment && locations.length > 0) {
                  sendMoneyStore.form.deliveryMethodLocation = locations[0]
                } else {
                  sendMoneyStore.form.deliveryMethodLocation = '0'
                }
                sendMoneyStore.form.deliveryMethodAgent = agent
                sendMoneyStore.updateDeliveryMethodText()
                sendMoneyStore.goToNextStep()
              }
            } catch (ex) {
              appStore.logException('Exception during selectCity', ex)
              appStore.messageBoxGenericError()
            }
          }
        }
      } catch (ex) {
        appStore.logException({
          text: 'Exception during selectAgent',
          exception: ex,
        })
        appStore.messageBoxGenericError()
      }
      loading.value = false

      if (agent.openPayment) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYOUT_LOCATION_SELECTED,
          traits: {
            payoutCountryType: SEGMENT_PAYOUT_AGENT_TYPE.OPEN_NETWORK,
            payoutAgentName: agent.name,
          },
        })
      } else if (!agent.openPayment && agent.locationCount === 1) {
        const location = await recipientsStore.getAgentLocations({
          agent: agent,
          country: country,
          city: selectedCity.value,
          amount: sendMoneyStore.getAmountTo(false),
          deliveryMethod: sendMoneyStore.form.deliveryMethod,
          currency: currency,
        })

        analyticsStore.track({
          event: SEGMENT_EVENTS.PAYOUT_LOCATION_SELECTED,
          traits: {
            payoutCountryType: SEGMENT_PAYOUT_AGENT_TYPE.POINT_TO_POINT,
            payoutAgentName: location[0].agentName,
            payoutLocationId: location[0].id,
            payoutLocationName: location[0].name,
            payoutLocationAddress:
              location[0].street + ', ' + location[0].city + ', ' + location[0].state,
          },
        })
      }
    }

    const showAgentDetails = async (agent) => {
      agentDetails.value = agent // for headline
      agentDetailsLoading.value = true
      showAgentDetailsModal.value = true

      try {
        const locations = await recipientsStore.getAgentLocations({
          agent: agent,
          country: country,
          city: selectedCity.value,
          amount: sendMoneyStore.getAmountTo(false),
          deliveryMethod: sendMoneyStore.form.deliveryMethod,
          currency: currency,
        })
        agentDetails.value = locations[0] // full details
        agentDetailsLoading.value = false
      } catch (ex) {
        agentDetailsLoading.value = false
        showAgentDetailsModal.value = false
        appStore.logException('Exception during getting agent details', ex)
        appStore.messageBoxGenericError()
      }
    }

    onMounted(async () => {
      // perform search on component mounted without interaction with search input
      try {
        // TODO BUG Fails when selectedRecipient is null
        if (selectedRecipient.value) {
          let stateName = selectedRecipient.value.state
          let states = await recipientsStore.getStates(country)
          let state = states.find((state) => state.value === selectedRecipient.value.state)
          if (state) {
            stateName = state.name
          }

          searchInput.value = selectedRecipient.value.recipientCity + ', ' + stateName
          // await searchCity(selectedRecipient.value.city + ', ' + stateName)
        }
      } catch (ex) {
        appStore.logException('Exception during getting states', ex)
        appStore.messageBoxGenericError()
      }
    })

    return {
      searchedCities,
      loadingCities,
      searchCity,
      selectCity,
      searchedAgents,
      logoBaseUrl,
      searchInput,
      $t,
      focus,
      selectAgent,
      agentDetails,
      agentDetailsLoading,
      showAgentDetails,
      showAgentDetailsModal,
      currencyFrom,
      formatCurrency,
      formatExchangeRate,
      hideCities,
      loading,
      blur,
    }
  },
}
</script>

<style scoped>
.results-empty {
  @apply text-center;
  @screen md {
    @apply flex items-center justify-center flex-col  h-full;
  }
}

.agent-logo {
  mix-blend-mode: multiply;
  @apply rounded-lg;
}

.cities-skeleton {
  ::v-deep .skeleton {
    @apply my-4;
  }
}
</style>
