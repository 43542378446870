<template>
  <div class="map-container">
    <AppSpinnerBig :loading="loading" />

    <MglMap
      :access-token="accessToken"
      :map-style="mapStyle"
      :attribution-control="false"
      @load="mapLoaded"
    >
      <MglNavigationControl position="top-right" :show-compass="false" />
      <slot name="markers" />
    </MglMap>
  </div>
</template>

<script>
import { ref, watch, toRefs } from '@vue/composition-api'
import { MglMap, MglNavigationControl } from 'vue-mapbox'
import { useEnvStore } from '@galileo/stores'
import 'mapbox-gl/dist/mapbox-gl.css'

import { AppSpinnerBig } from '@oen.web.vue2/ui'
export default {
  name: 'XeMap',
  components: {
    MglMap,
    MglNavigationControl,
    AppSpinnerBig,
  },
  props: {
    centerLat: {
      type: Number,
      default: 0,
    },
    centerLng: {
      type: Number,
      default: 0,
    },
    boundingBox: {
      type: Object,
      default: () => [],
    },
  },
  setup(props) {
    const loading = ref(true)
    let map = null

    let asyncActions = null
    const updateLocationOnSelect = async () => {
      if (asyncActions && props.centerLat && props.centerLng) {
        await asyncActions.flyTo({
          center: [props.centerLng, props.centerLat],
          zoom: 11,
          duration: 1000,
          easing: (t) => {
            return 1 - Math.pow(1 - t, 5)
          },
          // TODO if animation is essential set this, often off on slower devices
          //essential: true,
        })
      }
    }

    const updateLocationOnMapLoad = async () => {
      if (map && props.boundingBox) {
        // Generate camera configuration that displays all of the location markers
        // inside map viewport
        const cameraBounds = map.cameraForBounds(props.boundingBox, {
          padding: { top: 20, bottom: 100, left: 20, right: 20 },
        })
        cameraBounds.duration = 1000
        cameraBounds.easing = (t) => {
          return 1 - Math.pow(1 - t, 5)
        }
        await asyncActions.flyTo(cameraBounds)
      }
    }

    const mapLoaded = (event) => {
      asyncActions = event.component.actions
      map = event.map
      loading.value = false
      updateLocationOnMapLoad()
    }

    const { centerLat, centerLng, boundingBox } = toRefs(props)
    watch(centerLat, updateLocationOnSelect)
    watch(centerLng, updateLocationOnSelect)
    watch(boundingBox, updateLocationOnMapLoad)

    const envStore = useEnvStore()

    const accessToken = envStore.env.VUE_APP_MAP_BOX_ACCESS_TOKEN
    const mapStyle = envStore.env.VUE_APP_MAP_BOX_MAP_STYLE

    return {
      accessToken,
      mapStyle,
      mapLoaded,
      loading,
      updateLocationOnSelect,
    }
  },
}
</script>

<style scoped>
.map-container {
  @apply h-full;
}

::v-deep .mapboxgl-canvas {
  /* otherwise an outline will appear when it is focused */
  outline: 0px !important;
}

.map-container[rounded-right] {
  ::v-deep .mapboxgl-canvas-container {
    border-bottom-right-radius: 1.5rem;
    overflow: hidden;
  }
}

::v-deep .mapboxgl-ctrl-group {
  @apply flex flex-col w-10 h-20 bg-white;
  @apply rounded-2xl shadow-ria-2;
  @apply border-solid border-gray-light;
  border-width: 1px;
}

::v-deep .mapboxgl-ctrl-group button {
  @apply w-full p-0 m-0;
  flex-basis: 50%;
}

::v-deep .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-in {
  @apply rounded-t-2xl;

  &:focus {
    @apply rounded-t-2xl;
  }

  .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.75 6.25V0.25H6.25V6.25H0.25V7.75H6.25V13.75H7.75V7.75H13.75V6.25H7.75Z' fill='%23001133' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
  }
}

::v-deep .mapboxgl-ctrl-group .mapboxgl-ctrl-zoom-out {
  @apply border-gray-light rounded-b-2xl;

  &:focus {
    @apply rounded-b-2xl;
  }

  .mapboxgl-ctrl-icon {
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='2' viewBox='0 0 14 2' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.25 0.373077H13.75V1.87308H0.25V0.373077Z' fill='%23001133' fill-opacity='0.6'/%3E%3C/svg%3E%0A");
  }
}

::v-deep .mapboxgl-ctrl-logo {
  @apply max-h-4 bg-contain;
}
</style>
