import { computed, reactive, toRef, watch } from '@vue/composition-api'
import { useValidation } from 'vue-composable'

import { DynamicFormField } from '@oen.web.vue2/ui'

import {
  useFieldComponentHelper,
  useFieldPropsHelper,
  useFieldValidation,
} from '@galileo/composables/useFormHelpers'
import { useDynamicForm } from '@galileo/forms/useDynamicForm'

const createDynamicFormField = (field) => {
  const dynamicFormField = new DynamicFormField({
    field,
    value: '',
    hidden: false,
    disabled: false,
    props: useFieldPropsHelper(field),
    component: useFieldComponentHelper(field.type),
  })
  return reactive(dynamicFormField)
}

export const useBankDepositForm = () => {
  const { formValidation, formFields, hasFormFields, isFormInvalid } = useDynamicForm()
  const { createFormFieldValidation } = useFieldValidation()

  const setup = (fields) => {
    const fieldData = []
    const validationData = {}

    for (const field of fields) {
      const formField = createDynamicFormField(field)
      validationData[formField.name] = createFormFieldValidation(formField)

      fieldData.push(formField)
    }

    formValidation.value = useValidation(validationData)

    for (const formField of fieldData) {
      formField.validation = formValidation.value[formField.name]
      if (formField.parent) {
        formField.parent = formField.parent = fieldData.find((item) => item.id === formField.parent)
      }
    }

    formFields.value = fieldData

    // Watch each bank details field, if field containing masked input is
    // edited - clear all masked fields
    formFields.value.forEach((field) => {
      watch(toRef(field, 'value'), (newValue, oldValue) => {
        if (/[*•]/gi.test(oldValue)) {
          formFields.value.forEach((otherField) => {
            if (/[*•]/gi.test(otherField.value)) {
              otherField.value = ''
            }
          })
        }
      })
    })
  }

  return {
    setup,
    hasFormFields,
    isFormInvalid,
    formFields: computed(() => formFields.value),
    formValidation: computed(() => formValidation.value),
  }
}
